/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 58번째 로그에서는 맥북프로 16인치(와 ESC 키) 출시, VS Code 온라인, 깃헙 언어 사용 리포트 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "후원"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 | Patreon")), "\n"), "\n", React.createElement(_components.p, null, "정리 중..."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
